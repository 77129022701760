import React from "react"

// Import components
import SEO from "../components/global/seo"
import GlobalStyles from "../components/global/GlobalStyles"
import Header from "../components/organisms/Header"
import ContactUsPageLayout from "../components/layout/ContactUsPage"
import Footer from "../components/organisms/Footer"

const LandingPage = () => {
  return (
    <>
      <GlobalStyles />
      <SEO title="Services" />
      <Header />
      <ContactUsPageLayout />
      <Footer />
    </>
  )
}

export default LandingPage
