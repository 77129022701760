import React from "react"
import styled from "@emotion/styled"
import systemCss from "@styled-system/css"

// Media queries utils
import { sm, md, lg, xl } from "../../../utils/mediaQueries"

// Import assets
import DepthInfiniteElement1 from "../../../assets/images/illustrations/depth-infinity-element1.svg"
import DepthInfiniteElement3 from "../../../assets/images/illustrations/depth-infinity-element3.svg"

import EmailIcon from "../../../assets/images/icons/email-icon.svg"
import PhoneIcon from "../../../assets/images/icons/phone-icon.svg"
import LocationIcon from "../../../assets/images/icons/location-icon.svg"
import MapIcon from "../../../assets/images/icons/map-icon.svg"

const ContactSection = styled.div`
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;

  background-image: url(${DepthInfiniteElement3}), url(${DepthInfiniteElement1});
  background-position: right 12.5% bottom 7.5%, left 12.5% top 35%;
  background-size: 20rem auto, 20rem auto;
  background-repeat: no-repeat, no-repeat;

  ${lg} {
    background-position: left 1% bottom 5%, right 1% top 30%;
    background-size: 16rem auto, 16rem auto;
  }

  ${sm} {
    background-position: left 1% bottom 1%, right 1% top 18%;
    background-size: 10rem auto, 10rem auto;
  }
`

const ContactContainer = styled.div`
  display: flex;
  max-width: 1440px;
  margin: 0px auto;
  flex-direction: column;
  padding: 20rem 7rem 14rem;
  justify-content: flex-start;
  align-items: center;

  ${xl} {
    padding: 10rem 4rem;
  }

  ${sm} {
    padding: 6rem 2rem;
  }
`

const SectionHeading = styled.h1`
  text-align: center;
  font-weight: 700;
  line-height: 1.25;

  ${systemCss({
    fontSize: ["h3", "h2", "h2", "h1"],
    fontFamily: "primary",
    color: "black"
  })}
`

const SectionSubheading = styled.h4`
  text-align: center;
  font-weight: 400;
  line-height: 1.2;
  padding: 0px;
  margin: 0px;
  margin-top: 2rem;
  max-width: 85rem;

  ${systemCss({
    color: "black",
    fontSize: ["p", "h5", "h5", "h4"],
    fontFamily: "primary"
  })}

  ${sm} {
    margin-top: 1rem;
  }
`

const ContactCards = styled.div`
  display: grid;
  width: 100%;
  max-width: 87.5rem;
  margin-top: 8rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem;

  ${sm} {
    margin-top: 6rem;
    grid-gap: 2.4rem;
  }
`

const ContactCard = styled.div`
  display: flex;
  grid-column: span 1;
  height: 100%;
  padding: 4rem;
  border-radius: 8px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  ${systemCss({
    bg: "white"
  })}

  ${md} {
    grid-column: span 2;
  }
`

const ContactCardLg = styled(ContactCard)`
  grid-column: span 2;
`

const ContactCardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${sm} {
    align-items: center;
  }
`

const ContactCardIcon = styled.img`
  display: block;
  height: 8rem;
  width: auto;

  ${sm} {
    margin: 1rem;
  }
`

const ContactCardHeading = styled.h4`
  margin: 0px;
  margin-top: 2rem;
  font-weight: 700;
  line-height: 1.2;
  padding: 0px;

  ${systemCss({
    color: "dark",
    fontSize: ["p", "h5", "h5", "h4"],
    fontFamily: "primary"
  })}

  ${sm} {
    margin-top: 1rem;
  }
`

const ContactCardContent = styled.p`
  margin: 0px;
  margin-top: 1rem;
  font-weight: 400;
  line-height: 1.35;
  padding: 0px;

  ${systemCss({
    color: "black",
    fontSize: "p",
    fontFamily: "primary"
  })}

  & > a {
    ${systemCss({
      color: "black",
      fontSize: "p",
      fontFamily: "primary"
    })}
  }

  ${sm} {
    align-self: center;
    text-align: center;
  }
`

const ContactCardContainerLg = styled(ContactCardContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${sm} {
    flex-direction: column;
  }
`

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${sm} {
    align-items: center;
  }
`

const LocationDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${sm} {
    margin-top: 2.5rem;
  }
`

const LocationMapIcon = styled.img`
  height: 13rem;
  display: block;
  width: auto;

  ${sm} {
    height: 6.6rem;
  }
`

const LocationLink = styled.a`
  margin: 0px;
  margin-top: 1.4rem;
  font-weight: 400;
  line-height: 1.25;
  padding: 0px;
  text-decoration: underline;

  ${systemCss({
    color: "black",
    fontSize: ["base", "link"],
    fontFamily: "primary"
  })}

  ${sm} {
    margin-top: 0.75rem;
  }
`

export default props => {
  return (
    <ContactSection>
      <ContactContainer>
        <SectionHeading>Let’s work together</SectionHeading>
        <SectionSubheading>
          We are excited to take on your new project and help you build
          something amazing!
        </SectionSubheading>

        <ContactCards>
          <ContactCard>
            <ContactCardContainer>
              <ContactCardIcon src={EmailIcon} alt="email us icon" />
              <ContactCardHeading>Email Us</ContactCardHeading>
              <ContactCardContent>
                <a href="mailto:support@tenxgeeks.com">support@tenxgeeks.com</a>
              </ContactCardContent>
            </ContactCardContainer>
          </ContactCard>

          <ContactCard>
            <ContactCardContainer>
              <ContactCardIcon src={PhoneIcon} alt="phone icon" />
              <ContactCardHeading>Phone No</ContactCardHeading>
              <ContactCardContent>
                <a href="tel:+918168238248">+91-8168238248</a>
              </ContactCardContent>
            </ContactCardContainer>
          </ContactCard>

          <ContactCardLg>
            <ContactCardContainerLg>
              <CardDetails>
                <ContactCardIcon src={LocationIcon} alt="location icon" />
                <ContactCardHeading>Office Address</ContactCardHeading>
                <ContactCardContent>
                  BTM Layout, 1st Stage, <br />
                  Bengaluru, Karnataka 560029
                </ContactCardContent>
              </CardDetails>
              <LocationDetails>
                <LocationMapIcon alt="location icon" src={MapIcon} />
                <LocationLink
                  href="https://www.google.com/maps/place/BTM+1st+Stage,+BTM+Layout,+Bengaluru,+Karnataka+560029"
                  target="_blank"
                >
                  View Location
                </LocationLink>
              </LocationDetails>
            </ContactCardContainerLg>
          </ContactCardLg>
        </ContactCards>
      </ContactContainer>
    </ContactSection>
  )
}
